import React, { useContext, useState, useEffect } from "react";
import isEmpty from "is-empty";
import LocalStorageService from "../utils/LocalStorageService";

const UserContext = React.createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [userData, setUserData] = useState({});

  const onSubmitUserData = (data) => {
    setUserData(data);
    LocalStorageService.setItem("user", data);
  };

  useEffect(() => {
    const user = LocalStorageService.getItem("user");
    if (!isEmpty(user)) {
      setUserData(user);
    }
  }, []);

  const value = {
    userData,

    onSubmitUserData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
