import React from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";

import DeleteIcon from "@material-ui/icons/Delete";
import Pagination from "../../Pagination/Pagination";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import Select from "@material-ui/core/Select";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { ACCESS_TOKEN } from "../../../utils/config";

const LobbyManagement = () => {
  const [pageSize, setPageSize] = useState(5);
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    loadUser();
  }, [searchInput, currentPage]);

  const filterData = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);
  };

  const loadUser = async () => {
    try {
      const res = await axios.get("admin/get-poker-lobby");
      setUsers(res.data.data);

      const filteredUsers = res.data.data.filter(
        (user) =>
          user.game_name.toLowerCase().includes(searchInput.toLowerCase()) ||
          user.game_json_data.minimum_player
            .toString()
            .includes(searchInput.toLowerCase()) ||
          user.game_json_data.maximum_player
            .toString()
            .includes(searchInput.toLowerCase()) ||
          user.game_json_data.commission
            .toString()
            .includes(searchInput.toLowerCase()) ||
          user.game_json_data.small_blind
            .toString()
            .includes(searchInput.toLowerCase()) ||
          user.game_json_data.big_blind
            .toString()
            .includes(searchInput.toLowerCase()) ||
          (user.game_status === "1" &&
            "Active".toLowerCase().includes(searchInput.toLowerCase())) ||
          (user.game_status === "0" &&
            "Inactive".toLowerCase().includes(searchInput.toLowerCase())) ||
          (user.game_status === "2" &&
            "Deleted".toLowerCase().includes(searchInput.toLowerCase()))
      );

      setTableFilter(filteredUsers);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`admin/poker-lobby/${id}`);
      loadUser();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const formatLocalTime = (utcTimeString) => {
    const date = new Date(utcTimeString);
    return date.toLocaleString();
  };

  const AddUserLobby = () => {
    console.log("add user in the lobby ");
  };

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h6 className="m-0 font-weight-bold text-primary">
              Manage Lobby
              <span style={{ marginLeft: 990 + "px" }} onClick={AddUserLobby}>
                {" "}
                <Link className="btn btn-success" to="/lobby/add">
                  + CREATE
                </Link>
              </span>
            </h6>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div class="row">
                <div className="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div id="dataTable_filter" class="dataTables_filter">
                    <label>
                      Search:
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder=""
                        aria-controls="dataTable"
                        value={searchInput}
                        onChange={filterData}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table table-bordered "
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                    role="grid"
                    aria-describedby="dataTable_info"
                    style={{ width: 100 + "%" }}
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting sorting_asc"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-sort="ascending"
                          aria-label="Name: activate to sort column descending"
                          style={{ width: 57 + "px" }}
                        >
                          Sr. No
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Position: activate to sort column ascending"
                          style={{ width: 61 + "px" }}
                        >
                          Room Title
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Office: activate to sort column ascending"
                          style={{ width: 49 + "px" }}
                        >
                          Minimum Players
                        </th>

                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Start date: activate to sort column ascending"
                          style={{ width: 31 + "px" }}
                        >
                          Maximum Players
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 67 + "px" }}
                        >
                          Commission
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 67 + "px" }}
                        >
                          Small Blind
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 67 + "px" }}
                        >
                          Big Blind
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 67 + "px" }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 67 + "px" }}
                        >
                          Created At
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 57 + "px" }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                      {tableFilter
                        ?.slice(
                          pageSize * (currentPage - 1),
                          pageSize * currentPage
                        )
                        .map((data, index) => (
                          <tr className="odd">
                            <td className="sorting_1">
                              {index + 1 + pageSize * (currentPage - 1)}
                            </td>
                            <td>{data.game_name || ""}</td>
                            <td>{data.game_json_data.minimum_player || ""}</td>
                            <td>{data.game_json_data.maximum_player || ""}</td>
                            <td>{data.game_json_data.commission || ""}</td>
                            <td>{data.game_json_data.small_blind || ""}</td>
                            <td>{data.game_json_data.big_blind || ""}</td>
                            <td>
                              {data.game_status === "1"
                                ? "Active"
                                : data.game_json_data.status === "0"
                                ? "Inactive"
                                : data.game_json_data.status === "2"
                                ? "Deleted"
                                : null}
                            </td>

                            <td>{formatLocalTime(data.createdAt || "")}</td>
                            <td>
                              <Link to={`/lobby/${data.game_id}`}>
                                <VisibilityIcon
                                  fontSize="small"
                                  style={{ color: "blue" }}
                                ></VisibilityIcon>
                              </Link>
                              {"  "}
                              <Link to={`/lobby/edit/${data.game_id}`}>
                                <CreateIcon fontSize="small"></CreateIcon>
                              </Link>
                              {"  "}
                              <DeleteIcon
                                fontSize="small"
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={(e) =>
                                  window.confirm(
                                    "Are you sure you wish to delete this item?"
                                  ) && handleDelete(data.game_id)
                                }
                              ></DeleteIcon>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={searchInput ? tableFilter.length : users.length}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LobbyManagement;
