import React from "react";

const overview = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Total Sold
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      40,00
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Earnings (Monthly) Card Example */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                      Token Alloted to User
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      215,000
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Earnings (Monthly) Card Example */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Escrow Account
                    </div>

                    <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      50
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Pending Requests Card Example */}
          <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                      Admin Token
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      45,83,218
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-comments fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">
              All Transactions
            </h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper dt-bootstrap4"
              >
                <div class="row">
                  <div className="col-sm-12 col-md-6"></div>
                  <div class="col-sm-12 col-md-6">
                    <div id="dataTable_filter" class="dataTables_filter">
                      <label>
                        Search:
                        <input
                          type="search"
                          class="form-control form-control-sm"
                          placeholder=""
                          aria-controls="dataTable"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                      role="grid"
                      aria-describedby="dataTable_info"
                      style={{ width: 100 + "%" }}
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="Name: activate to sort column descending"
                            style={{ width: 57 + "px" }}
                          >
                            Sr. No
                          </th>
                          <th
                            className="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="Name: activate to sort column descending"
                            style={{ width: 57 + "px" }}
                          >
                            User Name <br /> Wallet Address
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Position: activate to sort column ascending"
                            style={{ width: 61 + "px" }}
                          >
                            Amount <br />
                            Transaction Type
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Office: activate to sort column ascending"
                            style={{ width: 49 + "px" }}
                          >
                            Transaction ID
                          </th>

                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style={{ width: 67 + "px" }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th rowspan="1" colspan="1">
                            Sr. No
                          </th>
                          <th rowspan="1" colspan="1">
                            User Name <br />
                            Wallet Address
                          </th>
                          <th rowspan="1" colspan="1">
                            Amount <br />
                            Transaction Type
                          </th>
                          <th rowspan="1" colspan="1">
                            Transaction/ID
                          </th>

                          <th rowspan="1" colspan="1">
                            Status
                          </th>
                        </tr>
                      </tfoot>
                      <tbody>
                        <tr className="odd">
                          <td className="sorting_1">1</td>
                          <td>
                            airi <br />
                            TM4maYMxXqSuaXzSEyBSqQ8bma9Sv35Zeg
                          </td>
                          <td>
                            10000
                            <br />
                            Buy RVT
                          </td>
                          <td>abcd12</td>
                          <td>Verify</td>
                        </tr>
                        <tr className="odd">
                          <td className="sorting_1">2</td>
                          <td>
                            airi
                            <br />
                            TM4maYMxXqSuaXzSEyBSqQ8bma9Sv35Zeg
                          </td>
                          <td>
                            20000
                            <br />
                            Buy RVT
                          </td>
                          <td>adbs12</td>
                          <td>Verify</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-5">
                    <div
                      className="dataTables_info"
                      id="dataTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 1 to 10 of 57 entries
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="dataTable_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button page-item previous disabled"
                          id="dataTable_previous"
                        >
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="0"
                            tabindex="0"
                            className="page-link"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="paginate_button page-item active">
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="1"
                            tabindex="0"
                            className="page-link"
                          >
                            1
                          </a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="2"
                            tabindex="0"
                            className="page-link"
                          >
                            2
                          </a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="3"
                            tabindex="0"
                            className="page-link"
                          >
                            3
                          </a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="4"
                            tabindex="0"
                            className="page-link"
                          >
                            4
                          </a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="5"
                            tabindex="0"
                            className="page-link"
                          >
                            5
                          </a>
                        </li>
                        <li className="paginate_button page-item ">
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="6"
                            tabindex="0"
                            className="page-link"
                          >
                            6
                          </a>
                        </li>
                        <li
                          className="paginate_button page-item next"
                          id="dataTable_next"
                        >
                          <a
                            href="#"
                            aria-controls="dataTable"
                            data-dt-idx="7"
                            tabindex="0"
                            className="page-link"
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default overview;
