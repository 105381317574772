import React, { useState } from "react";
import logo from "../assets/css/download.png";
import { NavLink, BrowserRouter, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
const Leftside = () => {
  const [show, setShow] = useState({
    contract: false,
    poker: false,
  });
  const change = (event) => {
    console.log(event.target.name);
    if (event.target.name == "contract") {
      setShow((prev) => {
        return {
          ...prev,
          [event.target.name]: !prev.contract,
        };
      });
    }
    if (event.target.name == "poker") {
      setShow((prev) => {
        return {
          ...prev,
          [event.target.name]: !prev.poker,
        };
      });
    }
  };
  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        {/* Sidebar - Brand */}
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="index.html"
        >
          <div className="sidebar-brand-icon">
            <img
              style={{ width: 100 + "%", objectFit: "contain" }}
              alt=""
              src={logo}
            ></img>
          </div>
          <div className="sidebar-brand-text mx-3"></div>
        </a>
        {/* Divider */}
        <hr className="sidebar-divider my-0" />
        {/* Nav Item - Dashboard */}
        <li className="nav-item active">
          <NavLink exact className="nav-link" to="/dashboard">
            <i className="fas fa-fw fa-tachometer-alt" />
            <span>Dashboard</span>
          </NavLink>
        </li>
        {/* Divider */}
        <hr className="sidebar-divider" />
        {/* Heading */}
        <div className="sidebar-heading">Interface</div>
        {/* Nav Item - Pages Collapse Menu */}
        <li className="nav-item">
          <NavLink to="/dashboard/users" className="nav-link">
            <i className="fas fa-fw fa-cog" />
            <span>Users</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact className="nav-link" to="/dashboard/stream">
            <i className="fas fa-fw fa-table" />
            <span>Stream</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard/earning">
            <i className="fas fa-fw fa-chart-area" />
            <span>Earnings</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard/fee">
            <i className="fas fa-fw fa-chart-area" />
            <span>Manage Fee</span>
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard/users-kyc">
            <i className="fas fa-fw fa-chart-area" />
            <span>KYC</span>
          </NavLink>
        </li>


        {/* Divider */}
        <hr className="sidebar-divider" />
        {/* Heading */}
        <div className="sidebar-heading"> Smart Contract</div>
        {/* Nav Item - Pages Collapse Menu */}
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapse"
            aria-expanded="true"
            aria-controls="collapsePages"
            name="contract"
            onClick={change}
          >
            <i className="fas fa-fw fa-folder" />
            <span>Contract Management</span>
          </a>
          {/* {console.log(show.contract)} */}
          <div
            id="collapse"
            className={`collapse ${show.contract == true ? "show" : " "}`}
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <a className="collapse-item" href="/dashboard/contract-overview">
                Contract Overview
              </a>
              <a className="collapse-item">Change fees</a>
              <a className="collapse-item">Admin Control</a>
              <div className="collapse-divider" />
            </div>
          </div>
        </li>
        <div className="sidebar-heading">Game Management</div>
        {/* Nav Item - Pages Collapse Menu */}
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapsePages"
            aria-expanded="true"
            aria-controls="collapsePages"
            name="poker"
            onClick={change}
          >
            <i className="fas fa-fw fa-folder" />
            <span>Poker Management</span>
          </a>
          <div
            id="collapsePages"
            className={`collapse ${show.poker == true ? "show" : " "}`}
            aria-labelledby="headingPages"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <NavLink className="collapse-item" to="/dashboard/pokerusers">
                Poker Users
              </NavLink>
              <NavLink className="collapse-item" to="/dashboard/lobby">
                Lobby Management
              </NavLink>
              <NavLink className="collapse-item" to="/dashboard/all-hands">
                All Hands
              </NavLink>
              {/* <a className="collapse-item" href="forgot-password.html">
                 Admin Control
              </a> */}
              <div className="collapse-divider" />
            </div>
          </div>
        </li>

        {/* Nav Item - Charts */}
        {/* <li className="nav-item">
          <a className="nav-link" href="charts.html">
            <i className="fas fa-fw fa-chart-area" />
            <span>Charts</span>
          </a>
        </li> */}
        {/* Nav Item - Tables */}

        <li className="nav-item">
          {/* <NavLink
            exact
            className="nav-link"
            activeClassName="active"
            to="/dashboard/user"
          >
            <i className="fas fa-fw fa-table" />

            <span> All Users</span>
          </NavLink> */}
        </li>
        {/* Divider */}
        <hr className="sidebar-divider d-none d-md-block" />
        {/* Sidebar Toggler (Sidebar) */}
        {/* <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle" />
        </div> */}
      </ul>
    </>
  );
};

export default Leftside;
