import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { useUser } from "../context/UserProvider";

export default function LoginPage({ setToken }) {
  let history = useHistory();
  const { onSubmitUserData } = useUser();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState("");

  const userLogin = async (payLoad) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_REVIT_URL}/auth/login`,
        payLoad
      );
      return data;
    } catch (err) {
      return err;
    }
  };

  const onSubmit = async (data) => {
    // data.preventDefault();
    //console.log(data.email)
    const { email, password } = data;
    const payload = {
      email,
      password,
      mobile: "",
      isAdmin: 1,
    };
    //console.log(payload)
    const res = await userLogin(payload);

    if (res && res?.status) {
      localStorage.setItem("token", res.data.token);
      onSubmitUserData(res.data.user);
      //console.log(res.data.token)
      history.push("/dashboard");
    } else {
      setError(res?.response?.data?.message);
    }
    // const token= loginUser({email,password,mobile});
    // setToken(token);
  };

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <form className="user" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Email Address..."
                            {...register("email", { required: true })}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control form-control-user"
                            id="exampleInputPassword"
                            placeholder="Password"
                            {...register("password", { required: true })}
                          />
                        </div>

                        {error && <p className="alert alert-danger">{error}</p>}

                        <button className="btn btn-primary btn-user btn-block">
                          Login
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
