import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { Redirect } from "react-router";
import AuthRoutes from "./Routes/AuthRoutes";
import Dashboard from "./Dashboard/Dashboard";
import LoginPage from "./Login/LoginPage";
import { UserProvider } from "./context/UserProvider";
import LobbyManagement from "./Dashboard/Game Management/Lobby/Lobby";
import LobbyTable from "./Dashboard/Game Management/Lobby/LobbyTable";
import {  ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <div>
        <BrowserRouter>
       <ToastContainer/>
          <UserProvider>
            <Switch>
              <Route path="/login" component={LoginPage} />
              <AuthRoutes path="/dashboard" component={Dashboard} />
              {/* <Route path="/dashboard/lobby" component={} /> */}
              <Redirect to="/login" />
            </Switch>
          </UserProvider>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
