import React from "react";
import { useHistory } from "react-router-dom";

const Card = (props) => {
  const { data } = props;
  let history = useHistory();
  return (
    <div className="row">
      {data.map((item) => {
        return (
          <div
            className="col-xl-3 col-md-6 mb-4"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (item.name === "totalAdminShare") {
                history.push("/dashboard/stream");
              }
            }}
          >
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      {item.name === "totalUser"
                        ? "Total User"
                        : item.name === "totalStream"
                        ? "Total Stream"
                        : item.name === "totalLiveStream"
                        ? "Total Live Stream"
                        : item.name === "totalAdminShare"
                        ? "Total Stream Earning"
                        : null}
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      {item.value}
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Card;
