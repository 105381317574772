import React from "react";

import Navbar from "./Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";
import AddUser from "./AddUser";
import EditUser from "./EditUser";

import User from "./User";
import Lobby from "./Lobby";
function LobbyTable(props) {
  return (
    <Router>
      <div>
        <Navbar />
        <Switch>
          <Route exact path="/dashboard/lobby" component={Lobby} />
    
          <Route exact path="/lobby/add" component={AddUser} />
          <Route exact path="/lobby/edit/:id" component={EditUser} />
          <Route exact path="/lobby/:id" component={User} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default LobbyTable;
