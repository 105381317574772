import React from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
function ViewuserItem(props) {
  const [user, setUser] = useState({
    userName: "",
    clubName: "",
    clubStatus: "",
  });
  const { id } = useParams();
  useEffect(() => {
    loadUser();
  }, []);
  const loadUser = async () => {
    const res = await axios.get(`admin/products/${id}`);
    console.log(res.data.data);
    setUser(res.data);
  };
  return (
    <div className="container py-4">
      <Link className="btn btn-primary" to="/dashboard/club">
        Back
      </Link>
      <hr />
      <ul className="list-group w-50">
        <li className="list-group-item">Unique Club Id: {user.uniqueClubId}</li>
        {/* <li className="list-group-item">User Name: {user.userName}</li>
        <li className="list-group-item">Club Name: {user.clubName}</li>
        <li className="list-group-item">Club Request Count: {user.level}</li>
        <li className="list-group-item">Club Status: {user.clubStatus}</li> */}
      </ul>
    </div>
  );
}

export default ViewuserItem;
