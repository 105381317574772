import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const ViewuserStream = () => {
  const [user, setUser] = useState({
    uniqueClubId: "",
    userId: "",
    userName: "",
    clubName: "",
    clubStatus: "",
  });
  const { id } = useParams();
  useEffect(() => {
    loadUser();
  }, []);
  const loadUser = async () => {
    const res = await axios.get(`admin/streams`);
    console.log(res.data.records.data);
    setUser(res.data.records.data[0]);
  };
  return (
    <div className="container py-4">
      <Link className="btn btn-primary" to="/dashboard/users">
        Back
      </Link>
      <hr />
      <ul className="list-group w-50">
        <li className="list-group-item">Unique Club Id: {user.uniqueClubId}</li>
        <li className="list-group-item">User Name: {user.userName}</li>
        <li className="list-group-item">Club Name: {user.clubName}</li>
        <li className="list-group-item">Club Request Count: {user.level}</li>
        <li className="list-group-item">Club Status: {user.clubStatus}</li>
      </ul>
    </div>
  );
};

export default ViewuserStream;
