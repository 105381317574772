import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import $ from "jquery";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "./assets/css/style.css";
import "./assets/css/datablesBootstrap.css";
import "./assets/css/fontawesome.css";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (reqConfig) => {
    const reqConfiglocal = reqConfig;
    const token = localStorage.getItem("token");
    if (token) {
      reqConfiglocal.headers.Authorization = `${token}`;
    }

    return reqConfiglocal;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error, "error");
    if (error?.response?.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
