import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Pagination from "../../Pagination/Pagination";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import Select from "@material-ui/core/Select";
import { useMemo } from "react";
import Navbar from "./Navbar";
const AllHands = () => {
  const [pageSize, setPageSize] = useState(5);
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: {},
    params: {},
  };

  const formatCard = (cardString) => {
    const ranks = {
      A: "A",
      K: "K",
      Q: "Q",
      J: "J",
      T: "10",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
    };

    const suits = {
      c: "\u2663", // Clubs
      d: "\u2666", // Diamonds
      h: "\u2665", // Hearts
      s: "\u2660", // Spades
      j: "\uD83C", // Joker
    };

    const rank = ranks[cardString.substring(0, cardString.length - 1)];
    const suit = suits[cardString[cardString.length - 1]];
    const formattedRank =
      rank !== undefined
        ? rank
        : cardString.substring(0, cardString.length - 1);

    return `${formattedRank}${suit}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("api/v1/game", config);
        setUsers(result.data.data.rows);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filterData = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setValue(searchValue);

    if (searchValue === "") {
      setTableFilter([]);
    } else {
      const filteredTable = users.filter((user) =>
        Object.values(user).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue)
        )
      );
      setTableFilter(filteredTable);
    }

    setCurrentPage(1);
  };

  const handleChange = (event) => {
    setPageSize(event.target.value);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return tableFilter.length > 0
      ? tableFilter.slice(firstPageIndex, lastPageIndex)
      : users.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, tableFilter, users]);

  // const currentTable = useMemo(() => {
  //   const dataToUse = tableFilter.length > 0 ? tableFilter : users;
  //   const firstPageIndex = (currentPage - 1) * pageSize;
  //   const lastPageIndex = firstPageIndex + pageSize;
  //   return dataToUse.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage, tableFilter, pageSize, users]);

  useEffect(() => {
    const getAllHandsData = async () => {
      try {
        const res = await axios.get("admin/get-all-hands");
        setUsers(res.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getAllHandsData();
  }, [currentPage, value]);

  console.log("all hand data users is", users);
  // useEffect(() => {
  //   getAllHandsData();
  // }, [users, tableFilter]);

  const formatLocalTime = (utcTimeString) => {
    const date = new Date(utcTimeString);
    return date.toLocaleString();
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">All Hands</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper dt-bootstrap4"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6"></div>
                  <div className="col-sm-12 col-md-6">
                    <div id="dataTable_filter" className="dataTables_filter">
                      <label>
                        Search:
                        <input
                          type="search"
                          className="form-control form-control-sm"
                          placeholder=""
                          aria-controls="dataTable"
                          value={value}
                          onChange={filterData}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                      role="grid"
                      aria-describedby="dataTable_info"
                      style={{ width: 100 + "%" }}
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="Name: activate to sort column descending"
                            style={{ width: 5 + "px" }}
                          >
                            Sr. No
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Position: activate to sort column ascending"
                            style={{ width: 61 + "px" }}
                          >
                            Table ID
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Position: activate to sort column ascending"
                            style={{ width: 61 + "px" }}
                          >
                            Is Winner
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Position: activate to sort column ascending"
                            style={{ width: 61 + "px" }}
                          >
                            Winning Amount
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Office: activate to sort column ascending"
                            style={{ width: 49 + "px" }}
                          >
                            Hand Records
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Age: activate to sort column ascending"
                            style={{ width: 31 + "px" }}
                          >
                            Community Card
                          </th>

                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style={{ width: 67 + "px" }}
                          >
                            Created At
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style={{ width: 67 + "px" }}
                          >
                            Updated At
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTableData
                          ? currentTableData.map((data, index) => (
                              <tr className="odd" key={index}>
                                <td className="sorting_1">{index + 1}</td>
                                <td>{data.gameTableId}</td>
                                <td>{data.winner == 1 ? "Yes" : "No"}</td>
                                <td>{data.winAmount}</td>
                                <td>
                                  {data.handRecord?.length > 0 ? (
                                    <div>
                                      <span>Cards:</span>{" "}
                                      {data.handRecord.map((ele, i) => (
                                        <span key={i}>
                                          {formatCard(
                                            ele.cardRank + ele.cardSuit
                                          )}
                                          {i < data.handRecord.length - 1 &&
                                            ", "}
                                        </span>
                                      ))}
                                    </div>
                                  ) : (
                                    <p>No Data for hand record</p>
                                  )}
                                </td>
                                <td>
                                  {data.communityCards?.length > 0 ? (
                                    <div>
                                      <span>Cards:</span>{" "}
                                      {data.communityCards.map((ele, i) => (
                                        <span key={i}>
                                          {formatCard(
                                            ele.cardRank + ele.cardSuit
                                          )}
                                          {i < data.communityCards.length - 1 &&
                                            ", "}
                                        </span>
                                      ))}
                                    </div>
                                  ) : (
                                    <p>No Data for Community Cards record</p>
                                  )}
                                </td>

                                <td>{formatLocalTime(data.createdAt)}</td>
                                <td>{formatLocalTime(data.updatedAt)}</td>
                              </tr>
                            ))
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={
                    tableFilter.length > 0 ? tableFilter.length : users.length
                  }
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllHands;
