import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const AddUser = () => {
  let history = useHistory();
  const [gameCategories, setGameCategories] = useState([]);
  const [gameTypes, setGameTypes] = useState([]);

  const [user, setUser] = useState({
    room_name: "",
    game_category: "",
    game_type: "",
    minimum_player: "",
    maximum_player: "",
    minimum_buyin: "",
    maximum_buyin: "",
    small_blind: "",
    big_blind: "",
    commission: "",
    commission_cap: "",
    turn_timmer: "",
    multi_run: "",
    game_timmer: "",
  });

  const {
    room_name,
    game_category,
    game_type,
    minimum_player,
    maximum_player,
    minimum_buyin,
    maximum_buyin,
    small_blind,
    big_blind,
    commission,
    commission_cap,
    turn_timmer,
    multi_run,
    game_timmer,
  } = user;

  const accessToken = localStorage.getItem("token");

  const onInputChange = (e) => {
    const value =
      e.target.name === "game_type" || e.target.name === "game_category"
        ? parseInt(e.target.value, 10)
        : e.target.value;
    setUser({ ...user, [e.target.name]: value });
    if (e.target.name === "game_type") {
      game_type(value);
    }
    if (e.target.name === "game_category") {
      game_category(value);
    }
  };
  
  const handleTransition = () => {
    history.push("/dashboard/lobby");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const categoryId = user.game_category;
    const typeId = user.game_type;
    const payload = {
      game_category_id: categoryId,
      game_type_id: typeId,
      game_name: user.room_name,
      game_json_data: {
        room_name: user.room_name,
        minimum_player: user.minimum_player,
        maximum_player: user.maximum_player,
        minimum_buyin: user.minimum_buyin,
        maximum_buyin: user.maximum_buyin,
        small_blind: user.small_blind,
        big_blind: user.big_blind,
        commission: user.commission,
        commission_cap: user.commission_cap,
        turn_timmer: user.turn_timmer,
        multi_run: user.multi_run,
        game_timmer: user.game_timmer,
      },
    };

    try {
      const res = await axios.post("admin/add-poker-lobby", payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      history.push("/dashboard/lobby");
      console.log(res, "added user in poker lobby resData");
    } catch (error) {
      console.log("Error:", error.response);
    }
  };

  useEffect(() => {
    const fetchGameCategories = async () => {
      const response = await axios.get(`/admin/game-category`);
      setGameCategories(response.data.data);
      if (response.data.data.length > 0) {
        setUser((prevUser) => ({
          ...prevUser,
          game_category: response.data.data[0].game_category_id,
        }));
      }
    };
  
    const fetchGameTypes = async () => {
      const response = await axios.get(`/admin/game-type`);
      setGameTypes(response.data.data);
      if (response.data.data.length > 0) {
        setUser((prevUser) => ({
          ...prevUser,
          game_type: response.data.data[0].game_type_id,
        }));
      }
    };
  
    fetchGameCategories();
    fetchGameTypes();
  }, []);
  

  return (
    <div className="container">
      <div className="w-75 mx-auto shadow p-5">
        <h2 className="text-center mb-4">Creannte Lobby</h2>
        <form onSubmit={(e) => onSubmit(e)}>
          <div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Game Type</label>
                  <select
                    className="form-control"
                    name="game_type"
                    value={game_type}
                    onChange={(e) => {
                      game_type(e);
                    }}
                    required
                  >
                    {gameTypes.map((type) => (
                      <option key={type.game_type_id} value={type.game_type_id}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Game Category</label>
                  <select
                    className="form-control"
                    name="game_category"
                    placeholder="select game category"
                    value={game_category}
                    // onChange={(e) => onInputChange(e)}
                    onChange={(e) => {
                      game_category(e);
                    }}
                    required
                  >
                    {gameCategories.map((category) => (
                      <option
                        key={category.game_category_id}
                        value={category.game_category_id}
                      >
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Room Name<span className="text-danger"> * </span>{" "}
                    <span id="err_roomTitle" className="text-danger" />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="room_name"
                    id="roomTitle"
                    placeholder="Enter Room Name"
                    value={room_name}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Multi Run<span className="text-danger"> * </span>
                    <span id="err_players" className="text-danger" />
                  </label>
                  <input
                    type="text"
                    class="form-control calculation"
                    name="multi_run"
                    id="multiRun"
                    placeholder="Enter multi run"
                    value={multi_run}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Commission<span class="text-danger"> * </span>
                    <span id="err_commission" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control calculation"
                    name="commission"
                    id="commission"
                    placeholder="Enter Commission"
                    value={commission}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Commission Cap<span class="text-danger"> * </span>
                    <span id="err_smallBlind" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="commission_cap"
                    id="commission"
                    placeholder="Enter value"
                    value={commission_cap}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Small Blind<span class="text-danger"> * </span>
                    <span id="err_bigBlind" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="small_blind"
                    id="smallBlind"
                    placeholder="Enter small blind"
                    value={small_blind}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Big Blind <span class="text-danger"> * </span>
                    <span id="err_minBet" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="big_blind"
                    id="minBet"
                    placeholder="Enter big blind"
                    value={big_blind}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Minimum Players<span class="text-danger"> * </span>
                    <span id="err_bigBlind" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="minimum_player"
                    id="minPlayers"
                    placeholder="Enter min players"
                    value={minimum_player}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Maximum Players <span class="text-danger"> * </span>
                    <span id="err_minBet" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="maximum_player"
                    id="maxPlayers"
                    placeholder="Enter max players"
                    value={maximum_player}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Minimum Buyin<span class="text-danger"> * </span>
                    <span id="err_bigBlind" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="minimum_buyin"
                    id="minBuyin"
                    placeholder="Enter min buyin"
                    value={minimum_buyin}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Maximum Buyin <span class="text-danger"> * </span>
                    <span id="err_minBet" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="maximum_buyin"
                    id="MaxBuyin"
                    placeholder="Enter max buyin"
                    value={maximum_buyin}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Game Timmer<span class="text-danger"> * </span>
                    <span id="err_startGameTimmer" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="game_timmer"
                    id="startGameTimmer"
                    placeholder="Enter Game Timer"
                    value={game_timmer}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Turn Timer<span class="text-danger"> * </span>
                    <span id="err_nextRoundTimmer" class="text-danger"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="turn_timmer"
                    id="nextRoundTimmer"
                    placeholder="Enter Next Round Timer"
                    value={turn_timmer}
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn btn-success"
            onClick={onSubmit}
            disabled={
              !game_category ||
              !game_type ||
              !room_name ||
              !minimum_player ||
              !maximum_player ||
              !minimum_buyin ||
              !maximum_buyin ||
              !small_blind ||
              !big_blind ||
              !commission ||
              !commission_cap ||
              !turn_timmer ||
              !game_timmer ||
              !multi_run
            }
          >
            CREATE
          </button>
          &nbsp;
          <button
            className="btn btn-danger"
            onClick={handleTransition}
          >
            CANCEL
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
