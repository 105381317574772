import React from "react";
import Leftside from "../Layout/Leftside";
import Footer from "../Layout/Footer";
import Layout from "../Layout/Layout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import StreamTable from "./StreamTable";
import Users from "./Users/Users";
import Navbar from "../Layout/Navbar";
import ViewuserStream from "./Users/ViewuserStream";
import ViewuserItem from "./Users/ViewuserItem";

import Manage_Fees from "./Manage Fee/Manage_Fees";
import overview from "./Contract Management/Contract Overview/overview";
import Earning from "./Earning/Earning";
import LobbyTable from "./Game Management/Lobby/LobbyTable";
import AllHands from "./Game Management/PokerUsers/AllHands";
import PokerUsers from "./Game Management/PokerUsers";
import LobbyManagement from "./Game Management/Lobby/Lobby";
import Kyc from "./KYC/Kyc";
const Dashboard = () => {
  return (
    <div id="wrapper">
      <Leftside />

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Navbar />
          <Route exact path="/dashboard" component={Layout} />
          <Route exact path="/dashboard/users" component={Users} />
          <Route exact path="/dashboard/users-kyc" component={Kyc} />
          <Route
            exact
            path="/api/admin/streams/:id"
            component={ViewuserStream}
          />
          <Route exact path="/products/:id" component={ViewuserItem} />
          <Route exact path="/dashboard/stream" component={StreamTable} />
          <Route exact path="/dashboard/lobby" component={LobbyTable} />
          <Route exact path="/dashboard/pokerusers" component={PokerUsers} />
          <Route exact path="/dashboard/all-hands" component={AllHands} />
          <Route exact path="/dashboard/fee" component={Manage_Fees} />
          <Route
            exact
            path="/dashboard/contract-overview"
            component={overview}
          />
          <Route exact path="/dashboard/earning" component={Earning} />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
