import React from 'react'
import Pagination from "react-js-pagination";
import { useState, useEffect } from 'react';
import axios from 'axios';
import Form from "react-bootstrap/Form";
import './Kyc.css'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';

const Kyc = () => {

  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [users, setUsersData] = useState([])
  const [userkycStatus, setUserKycStatus] = useState('Pending');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [rejectReason, setRejectReason] = useState("");
  const [dataID, setDataID] = useState('');
  const [resMessage, setResMessage] = useState('');
  const [rejectBox, setRejectBox] = useState(false)
  const [rejectValue, setRejectValue] = useState('')
  const [statusRejected, setStatusRejected]= useState(false)
  const [approveStatus, setApproveStatus] = useState('Pending')
   
  const[rejectApiStatus, setRejectApiStatus]= useState('')
  const [userKycInfo, setUserKycInfo]= useState([]);
 var lastIndex = activePage*5;
 var firstIndex = lastIndex-5
 var onePageData =[]
  const handleRejectReasonChange = async (value, user_id, _id) => {
    
    setRejectReason(value)
    setRejectValue(true)
    if (rejectReason?.length == 0) {
      setRejectValue(false)
    }
    if (rejectReason === '') {
      setRejectValue(false)
    }
    else {
      setRejectValue(true)
    }

  }

  const submitReject = async (user_id, _id) => {

    setDataID(_id)
    // if(rejectReason===''){
    //   setRejectValue(false)
    // }
    const reject_reason = {
      reason_for_rejection: rejectReason
    }
    try {
      const status = "REJECT"
      const response = await axios.post(`admin/approveKyc/${user_id}?status=${status}`, reject_reason)
      if (response.status === 200) {
        setUserKycStatus("Rejected")
        setRejectApiStatus(response.status)
        console.log("reject api response", response)
        setStatusRejected(true)
        toast.success("rejected successfully")
        setRejectBox(false)
        getUsersKyc();
      }
      else {
        setUserKycStatus("Pending")
        toast.error("not rejected")
        setRejectApiStatus(response.status)
      }


    } catch (error) {
      toast.error("Something went wrong")
      setRejectApiStatus(500)

    }




  }
  const toastCheck = () => {
    console.log("toast chekr method")
    toast.success("Toats is working")
  }
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    console.log("ative page value is", activePage)
  };
 
  const getUsersKyc = async () => {
    // const response = await axios.get(`admin/get-user-kyc?page=${activePage}`);
    const response = await axios.get(`admin/get-user-kyc?page=${activePage}`);
    console.log("all user data", response);
    setUserKycInfo(response?.data?.data)
    console.log("user kycInfo is", userKycInfo)
   setTotalItemsCount(response?.data?.data?.length)
   console.log("Total items count", totalItemsCount)
    setUsersData(response?.data?.data.reverse())
    lastIndex = activePage*5;
    firstIndex = lastIndex-5
    
  };
  console.log("onepage data ", onePageData)
  const handleRejectReason = (user_id, _id, userkycInfo) => {
    setUserKycInfo(userkycInfo);
    setDataID(_id)
    setRejectBox(!rejectBox);
    console.log("reject box value", rejectBox);

  }
  const [kycApproved, setKycApproved]= useState(false)
  const handleApprove = async (userid, _id) => {
    try {
      const status = 'APPROVE'
      setDataID(_id)
      const response = await axios.post(`admin/approveKyc/${userid}?status=${status}`)
      if (response.status === 200) {
        setUserKycStatus("Approved")
        setApproveStatus('Approved')//for showing KYC Approved 
        toast.success("Kyc Approved")
        setKycApproved(true)
        getUsersKyc();
      }
      else{
        setUserKycStatus("Pending")
        toast.message(response.message)
      }
      console.log("response from approve api", response)
      //alert(response.data.data.user_kyc_status)
    } catch (error) {
      toast.error(error.message)
    }
   
  }


  async function handleKycStatus(id, status, _id) {
    setSelectedStatus(status)
    console.log("HandleKYC Selected Status is", status)
    //setCurrentStatus(_id)
    console.log("user id is", id);
    console.log("status value is", status);
    console.log("unique id is", _id);
    // const response=  await axios.post(`admin/approveKyc/${id}?status=${status}`)

    //   console.log("response from approve api", response)
    //   alert(response.data.data.user_kyc_status)

    console.log("status value", status, status === 'APPROVED')
    if (status === 'APPROVED') {

      const response = await axios.post(`admin/approveKyc/${id}?status=${status}`)

      console.log("response from approve api", response)
      alert(response.data.data.user_kyc_status)
    }

  }
  const [responsemessage, setResponseMessage] = useState('')
  const handleKycReject = async (id, status, reason) => {
    let data = { reason_for_rejection: reason }
    try {
      const response = await axios.post(`admin/approveKyc/${id}?status=${status}`, data)
      setResponseMessage(response.message);
      console.log("response.message is", response.message)
      if (response.status === 200) {
        toast.success(response.message)
        // alert(response.message)
        // toast({
        //   title: "Success",
        //   description: response.message,
        //   status: "success",
        //   duration: 3000,
        //   isClosable: true,
        // });
        setSelectedStatus('')
        //setCurrentStatus('')
        console.log("response from reject api", response)
        alert(response.data.data.user_kyc_status)
      }
      else if (response.status !== 200) {
        // toast({
        //   title: "Fail",
        //   description: response.message,
        //   status: "error",
        //   duration: 3000,
        //   isClosable: true,
        // });
        //alert(response.message)
        toast.error("else block", response.messgae)
        setSelectedStatus('')
        //setCurrentStatus('')
      }

    } catch (error) {
      if (error.response) {
        // Check if there is a response object in the error (axios error)
        toast.error("User KYC is already approved.", error.response.message); // Display the API response message
      } else {
        toast.error("Something went wrong"); // Display a generic error message
      }
      setSelectedStatus('');
      // setCurrentStatus('');
      console.log("error occurred", error);
    }



  }



  useEffect(() => {

    getUsersKyc();
  }, [])
 
  useEffect(() => {
    
  }, [approveStatus, statusRejected,kycApproved, rejectApiStatus])
  

  return (
    <>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">All Users KYC</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div
                id="dataTable_wrapper"
                className="dataTables_wrapper dt-bootstrap4"
              >

                <div className="row">
                  <div className="col-sm-12">
                    <table
                      className="table table-bordered "
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                      role="grid"
                      aria-describedby="dataTable_info"
                      style={{ width: 100 + "%" }}
                    >
                      <thead>
                        <tr role="row">

                          <th
                            className="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="Name: activate to sort column descending"
                            style={{ width: 57 + "px" }}
                          >
                            ID Photo
                          </th>

                          <th
                            className="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="Name: activate to sort column descending"
                            style={{ width: 57 + "px" }}
                          >
                            User Image 
                          </th>

                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="dataTable"
                            rowSpan="1"
                            colSpan="1"
                            aria-sort="none"
                            aria-label="Email: activate to sort column ascending"
                            style={{ width: "100px" }}
                          >
                            User Email
                          </th>

                          <th
                            className="sorting"
                            tabIndex="0"
                            aria-controls="dataTable"
                            rowSpan="1"
                            colSpan="1"
                            aria-sort="none"
                            aria-label="Email: activate to sort column ascending"
                            style={{ width: "100px" }}
                          >
                            User Name
                          </th>

                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Office: activate to sort column ascending"
                            style={{ width: 49 + "px" }}
                          >
                            ID Number & Id Type<br />
                           
                          </th>



                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style={{ width: 67 + "px" }}
                          >
                            User Status
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {console.log("users data is", users)}
                        {users.slice(firstIndex,lastIndex).map((data, index) =>  (
                          

                          <tr className="odd" key={data._id}>
                            <td className="sorting_1">
                              <div className="image-container">
                                <img src={data.id_image } alt={`ID Image ${index}`} />
                              </div>
                             
                              <br />
                            
                            </td>

                            <td>
                            <div className="image-container">
                                <img src={data.user_photo } alt={`User Image ${index}`} />
                              </div>
                            </td>

                            

                            <td className="sorting_1">
                              <div className="image-container">
                                <p style={{fontSize:'small'}}>{data.email}</p>
                              </div>
                              <br />

                            </td>
                            <td className="sorting_1">
                              <div className="image-container">
                                <p>{data.username}</p>
                              </div>
                              <br />

                            </td>

                     
                            <td> 
                            <p>Id Number  :{data.id_number}</p>
                            <p>Id Type  :{data.id_type}</p>
                            </td>

                            <td>
                            {/* add data._id if required for binding the data*/}
                              {/* {(dataID === data._id && userkycStatus === "Approved") && <p>KYC Approved</p>} */}
                              {(data._id && data.user_kyc_status === "Approved") && <p>KYC Approved</p>}
                            
                              {( data._id && data.user_kyc_status === "Pending") && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Button onClick={(e) => handleApprove(data.user_id, data._id)}
                                    style={{  borderRadius: '5px' }}
                                  >
                                    Approve
                                  </Button>

                                  <Button
                                    variant='danger'
                                    style={{ borderRadius: '5px' }}
                                    onClick={() => handleRejectReason(data.user_id, data._id, data)}
                                  >
                                    Reject
                                  </Button>
                                  <br></br>
                                </div>
                              ) 
                              }

                              {/* {dataID=== data._id && approveStatus ==="Approved" &&  <p>Approved</p>}  */}
                             
                              {dataID === data._id && rejectBox === true &&  (
                                <><label>Enter Reject Reason</label>

                                  <input
                                    name={data._id}
                                    type="text"
                                    required
                                    value={rejectReason}
                                    onChange={(e) => handleRejectReasonChange(e.target.value, data.user_id, data.id)}
                                    placeholder="Enter reject reason"
                                    style={{ width: '130px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)', marginRight:'6px' }}
                                  />
                                  {console.log("reject reason for payload", rejectReason)}
                                  <Button
                                    disabled={!rejectValue}
                                    onClick={() => submitReject(data.user_id, data._id)}

                                  >
                                    Submit</Button>
                                </>
                              )}
                              { (data._id && data.user_kyc_status === "Rejected") && <p>KYC Rejected. <br></br> <span> Reason: {data.reason_for_rejection}</span></p>}

                              

                              {/* {(selectedStatus === 'APPROVED' && currentStatus === data._id ) ? (
                                <p>Approved</p>
                              ) : (
                                <>
                                  <Form.Group>
                                    {data.status}
                                    <Form.Select
                                      className="me-sm-2"
                                      id="inlineFormCustomSelect"
                                      key={data?._id}
                                      name={data?._id}
                                      value={data?.user_kyc_status} // for separate changes
                                      onChange={(e) => handleKycStatus(data.user_id, e.target.value, data._id)}
                                    >
                                      <option value="-1">Choose...</option>
                                      <option value="APPROVED">Approve</option>
                                      <option value="REJECT">Reject</option>
                                    </Form.Select>
                                  </Form.Group>
                                  {console.log("data id is", data._id)}
                                  {console.log("selected status is", selectedStatus)}
                                  {console.log("condition", selectedStatus === 'REJECT' && currentStatus === data.id)}
                                  {selectedStatus === 'REJECT' && currentStatus === data._id && (
                                    <Form.Group>
                                      <div style={{ paddingTop: '8px' }}>
                                        <label>Give Reject Reason Below</label>
                                      </div>
                                      <div style={{ width: '100%', paddingBottom: '8px' }}>
                                        <input
                                          type="text"
                                          value={rejectReason}
                                          onChange={handleRejectReasonChange}
                                          placeholder="Enter reject reason"
                                          style={{ width: '130px' }}
                                        />
                                      </div>
                                      <div>
                                        <Button onClick={(e) => handleKycReject(data.user_id, selectedStatus, rejectReason)}>
                                          Submit
                                        </Button>
                                      </div>
                                    </Form.Group>
                                  )}
                                </>
                              )} */}

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div></div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={5}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    linkClass="page-link"
                    itemClass="paginate_button page-item"
                  />
                </div>
                {/* <button onClick={()=>toastCheck()}>
              
                Toast cheker
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Kyc