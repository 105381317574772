import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pagination from "react-js-pagination";
import Form from "react-bootstrap/Form";
import { useUser } from "../../context/UserProvider";
import { Button, InputGroup } from "react-bootstrap";

const Users = () => {
  const accessToken = localStorage.getItem("token");
  const { userData } = useUser();
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [users, setUsers] = useState([]);

  const [streamResponse, setstreamResponse] = useState([]);

  const [isAllowed, setAllowed] = useState(true)

  const clickAllowStream = () => {
    setAllowed(!isAllowed)
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const fetchData = useCallback(async () => {
    const response = await axios.get(`admin/users?page=${activePage}`);
    console.log(response.data.data, "resData");
    setTotalItemsCount(response.data.data.totalCount);
    console.log("total items count is ", totalItemsCount)
    //setUsers(response.data.data.users);
    //setUsers(response.data.data.users.reverse());
    // setUsers([ response.data.data.users, ...users]);
    setUsers( response.data.data.users);
    
    console.log("resonse is", response)
    console.log('users is', users)
  }, [activePage]);
  useEffect(() => {
    fetchData();

    //console.log(users);
  }, [fetchData]);

  const changeStatus = async (e, id) => {
    const { value } = e.target;
    let res;
    if (value === "-1") return;
    res = await axios.post(`admin/users/${id}/status`, {
      status: value,
    });
    fetchData();

    console.log(res, "check");
  };
  const changeUserRole = async (e, id) => {
    const { value } = e.target;
    if (value === "-1") return;
    const payload = {
      selfId: userData._id,
      userId: id,
      userType: value,
    };
    const res = await axios.put(`admin/updateUserType`, payload);
    fetchData();
    console.log(res, "check");
  };
  const getUserSearchHandler = async (e, id) => {
    const { value } = e.target;

    if (!value) {
      return fetchData();
    }

    const res = await axios.get(`admin/search?type=ALL&searchQuery=${value}`);
    setUsers(res.data.data);
    console.log(res, "resSearch");
  };

  const changeStreamStatus = async (e, id) => {
    const tempUsers = [...users];
    if (e.target.checked) {
      tempUsers.forEach((el) => {
        if (el._id === id) {
          el.blockStreamCreation = true;
        }
      });
      const res = await axios.put(`admin/blockStreamCreation`, {
        userId: id,
      });
      console.log(res, "resData");
    } else {
      tempUsers.forEach((el) => {
        if (el._id === id) {
          el.blockStreamCreation = false;
        }
      });
      const res = await axios.delete(`admin/blockStreamCreation`, {
        data: {
          userId: id,
        },
      });
      console.log(res, "resData");
    }
    setUsers(tempUsers);
  };

  console.log(userData, "userData");

  const allowStream = async (id, status) => {

    setAllowed(!isAllowed)

    try {
      const response = await axios.post(`admin/allow-user-to-stream/${id}`);
    
      console.log("stream response is", response);

      if (response.status===200) {
        // Stream allowed, handle success

        console.log("User is allowed to stream.");
      } else {
        alert(response.data.data.message)
        // Stream not allowed, display an error message
        console.log("User KYC is Pending. They cannot stream.");
        // You can display the error message to the user here.
      }

    } catch (error) {
     alert(error.response.data.message)
    }
    // if(status!=='Approved'){
    //   alert("Your KYC status is Pending")
    // }
    // else{
    //   try {
    //     const response = await axios.post(`admin/allow-user-to-stream/${id}`);
    //     console.log("stream response is", response);

    //     if (response.data.status) {
    //       // Stream allowed, handle success
    //       console.log("User is allowed to stream.");
    //     } else {
    //       // Stream not allowed, display an error message
    //       console.log("User KYC is Pending. They cannot stream.");
    //       // You can display the error message to the user here.
    //     }

    //   } catch (error) {
    //     console.log("error in allowing stream is", error);
    //   }
    
    // }
     



  }


  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">All Users</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div class="row">
                <div class="col-sm-4 col-md-4">
                  <div id="dataTable_filter" class="search_group">
                    <InputGroup className="mb-3">
                      <input
                        type="search"
                        class="form-control "
                        placeholder=""
                        aria-controls="dataTable"
                        onBlur={getUserSearchHandler}
                      />
                      <Button variant="outline-secondary" id="button-addon2">
                        Search
                      </Button>
                    </InputGroup>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table table-bordered "
                    id="dataTable"
                    width="100%"
                    cellSpacing="0"
                    role="grid"
                    aria-describedby="dataTable_info"
                    style={{ width: 100 + "%" }}
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting sorting_asc"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-sort="ascending"
                          aria-label="Name: activate to sort column descending"
                          style={{ width: 57 + "px" }}
                        >
                          Name <br /> Username
                        </th>

                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Office: activate to sort column ascending"
                          style={{ width: 49 + "px" }}
                        >
                          Email <br />
                          User Wallet Address
                        </th>

                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Start date: activate to sort column ascending"
                          style={{ width: 31 + "px" }}
                        >
                          Registration Date
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 67 + "px" }}
                        >
                          User Balance
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 67 + "px" }}
                        >
                          User Status
                        </th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th rowspan="1" colspan="1">
                          Name <br />
                          username
                        </th>

                        <th rowspan="1" colspan="1">
                          Email <br />
                          User Wallet Address
                        </th>

                        <th rowspan="1" colspan="1">
                          Registration Date
                        </th>
                        <th rowspan="1" colspan="1">
                          User Balance
                        </th>
                        <th rowspan="1" colspan="1">
                          User Status
                        </th>
                      </tr>
                    </tfoot>
                    <tbody>
                      {users.map((data) => (
                        console.log("users data is", data),
                        <tr className="odd">
                          <td className="sorting_1">
                            {data.name}
                            <br /> {data.username}
                            <p>
                              {(data.userType === "admin" ||
                                data.userType === "superAdmin") && (
                                  <strong>{data.userType}</strong>
                                )}
                            </p>
                          </td>

                          <td>
                            <span
                              className={
                                data.isEmailVerified ? "text-success" : ""
                              }
                            >
                              {data.email}
                            </span>{" "}
                            <br />
                            {data.address}
                          </td>
                          <td>{new Date(data.created_at).toLocaleString()}</td>
                          <td>
                            {data.balance}
                            <br />
                            <Link
                              to={`admin/streams/${data._id}`}
                              className="active"
                            >
                              View user Streams
                            </Link>
                            <br />
                            <Link to="/products/2" className="active">
                              View User Item
                            </Link>
                          </td>
                          <td>
                            {data.status}

                            <Form.Select
                              className="me-sm-2"
                              id="inlineFormCustomSelect"
                              onChange={(e) => changeStatus(e, data._id)}
                            >
                              <option value="-1">Choose...</option>
                              <option value="SUSPENDED">Suspended</option>
                              <option value="BLOCKED">Block</option>
                              <option value="ACTIVE">active</option>
                            </Form.Select>

                            <label className="mt-3">Change User Type</label>

                            <Form.Select
                              className="me-sm-2"
                              id="inlineFormCustomSelect"
                              onChange={(e) => changeUserRole(e, data._id)}
                            >
                              <option value="-1">Choose...</option>
                              <option value="user">User</option>
                              <option value="admin">Admin</option>
                            </Form.Select>

                            <p className="mt-3 mb-0">
                              {!data.blockStreamCreation
                                ? "Block Streaming"
                                : "Unblock Streaming"}
                            </p>
                            <div className="d-flex justify-content-center mt-1">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={
                                  !data.blockStreamCreation
                                    ? "Block"
                                    : "Unblock"
                                }
                                checked={data.blockStreamCreation}
                                onChange={(e) =>
                                  changeStreamStatus(e, data._id)
                                }
                              />
                            </div>

                            <p className="mt-3 mb-0">
                              Allow Streaming
                            </p>
                            <div className="d-flex justify-content-center mt-1">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label={
                                  !data.can_stream
                                    ? "Allow"
                                    : "Stop"
                                }

                                checked={data.can_stream}
                                
                                onChange={() =>
                                  allowStream(data._id, data.kyc_status)
                                }
                              />
                            </div>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div></div>
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  linkClass="page-link"
                  itemClass="paginate_button page-item"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
