import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Form, FormControl, InputGroup } from "react-bootstrap";
const PokerUsers = () => {
  const [allTransactions, setAllTransactions] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState("");
  const [email, setEmail] = useState("");

  const handleTypeChange = (e) => {
    const { value } = e.target;
    setTransactionTypes(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        // "api/user/profile/transactions"
        "admin/transactions?page=1"
      );
      console.log(res, "resData");
      const { data } = res.data;

      for (const list of data) {
        setAllTransactions(list.data);
      }

      //  setAllTransactions(response.data.data[0].data);
    };

    fetchData();
  }, []);

  const searchTransactionsHandler = async () => {
    let emailQuery = `?email=${email}`;
    if (!email) {
      emailQuery = "";
    }

    let transactionTypesQuery = `&${transactionTypes}`;
    if (transactionTypes == -1) {
      transactionTypesQuery = "";
    }

    const res = await axios.get(
      `admin/getTransactions${emailQuery}${transactionTypesQuery}`
    );
    const { data } = res.data;
    setAllTransactions(data);

    console.log(data, "resData");
  };

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="row-cols-12">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h5 className="m-0 font-weight-bold text-primary">
                All Transactions
              </h5>

              <div></div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="dataTable_wrapper"
              className="dataTables_wrapper dt-bootstrap4"
            >
              <div className="row mb-3">
                <div className="col-md-3 earning_col_fileter">
                  <Form.Label>Email</Form.Label>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Search by email"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Group controlId="exampleForm.SelectCustom">
                    <Form.Label className="mr-2">Transaction Type</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      onChange={handleTypeChange}
                    >
                      <option value="-1">Select Type</option>
                      <option value="BUY_TOKEN">Buy</option>
                      <option value="SELL_TOKEN">Sell</option>
                      <option value="USER_TRANSFER">Transfer</option>
                    </Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Label>&nbsp;</Form.Label>

                  <Form.Group controlId="exampleForm.ControlInput1">
                    <button
                      className="btn btn-primary"
                      onClick={searchTransactionsHandler}
                    >
                      Submit
                    </button>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table table-bordered "
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                    role="grid"
                    aria-describedby="dataTable_info"
                    style={{ width: 100 + "%" }}
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting sorting_asc"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-sort="ascending"
                          aria-label="Name: activate to sort column descending"
                          style={{ width: 5 + "px" }}
                        >
                          Sr. No
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Position: activate to sort column ascending"
                          style={{ width: 61 + "px" }}
                        >
                          Transaction ID
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Office: activate to sort column ascending"
                          style={{ width: 49 + "px" }}
                        >
                          User
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Age: activate to sort column ascending"
                          style={{ width: 31 + "px" }}
                        >
                          Transaction Type
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Start date: activate to sort column ascending"
                          style={{ width: 48 + "px" }}
                        >
                          Amount
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 47 + "px" }}
                        >
                          Status
                        </th>
                        <th
                          className="sorting"
                          tabindex="0"
                          aria-controls="dataTable"
                          rowspan="1"
                          colspan="1"
                          aria-label="Salary: activate to sort column ascending"
                          style={{ width: 47 + "px" }}
                        >
                          Date And Time
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {allTransactions.map((data, index) => (
                        <tr className="odd">
                          <td className="sorting_1">{index + 1}</td>
                          <td>
                            <a
                              href={` https://tronscan.org/#/transaction/${data.resp}`}
                            >
                              {data.resp}
                            </a>
                          </td>
                          <td style={{ minWidth: 180 }}>
                            From :{" "}
                            {data?.fromUserObj?.name || data?.fromUser?.name}
                            <p>
                              {data?.fromUserObj?.email ||
                                data?.fromUser?.email}
                            </p>
                            <p>
                              To: {data?.toUserObj?.name || data?.toUser?.name}
                            </p>
                            <p>
                              {data?.toUserObj?.email || data?.toUser?.email}
                            </p>
                          </td>
                          <td>{data.type}</td>
                          <td>{data.amount}</td>
                          <td>{data.status}</td>
                          <td>{new Date(data?.created_at).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-5">
                  {/* <div
                    className="dataTables_info"
                    id="dataTable_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 1 to 10 of 57 entries
                  </div> */}
                </div>
                <div className="col-sm-12 col-md-7">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="dataTable_paginate"
                  >
                    <ul className="pagination">
                      <li
                        className="paginate_button page-item previous disabled"
                        id="dataTable_previous"
                      >
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="0"
                          tabindex="0"
                          className="page-link"
                        >
                          Previous
                        </a>
                      </li>
                      <li className="paginate_button page-item active">
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="1"
                          tabindex="0"
                          className="page-link"
                        >
                          1
                        </a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="2"
                          tabindex="0"
                          className="page-link"
                        >
                          2
                        </a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="3"
                          tabindex="0"
                          className="page-link"
                        >
                          3
                        </a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="4"
                          tabindex="0"
                          className="page-link"
                        >
                          4
                        </a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="5"
                          tabindex="0"
                          className="page-link"
                        >
                          5
                        </a>
                      </li>
                      <li className="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="6"
                          tabindex="0"
                          className="page-link"
                        >
                          6
                        </a>
                      </li>
                      <li
                        className="paginate_button page-item next"
                        id="dataTable_next"
                      >
                        <a
                          href="#"
                          aria-controls="dataTable"
                          data-dt-idx="7"
                          tabindex="0"
                          className="page-link"
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PokerUsers;
