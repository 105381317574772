import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "./custom-cursor.css";
import { Form, Modal, Button, Table, InputGroup } from "react-bootstrap";
import { useUser } from "../context/UserProvider";
import Pagination from "react-js-pagination";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";

const StreamTable = () => {
  const { userData } = useUser();
  const [streams, setStreams] = useState([]);
  const [copiedStreams, setCopiedStreams] = useState([]);
  const [show, setShow] = useState(false);
  const [allParticipants, setAllParticipants] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [streamStatus, setStreamStatus] = useState("");
  const [streamMode, setStreamMode] = useState("");
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "months").format("YYYY-MM-DD HH:mm"),
    moment().format("YYYY-MM-DD HH:mm"),
  ]);


  const [showEndModal, setShowEndModal] = useState(false);
  const [streamTitle, setStreamTitle] = useState("");
  const [userEndId, setuserEndId] = useState("");

  const handleEndClick = (id) => {
    setuserEndId(id);
    setShowEndModal(true);
   
  };
    
  

  const handleConfirmEnd = (id) => {
    shutDownStream(id);
    setShowEndModal(false);
    
    
   
  };

  const shutDownStream =async (id)=>{
    try {
      const response = await axios.post(`admin/shutdown-stream/${userEndId}`);
      console.log("shutdown stream response is", response)
      if(response.status===200){
        getAllStreamHandler();
        alert("Stream ENded successfully");
      }

      console.log("end stream response is", response);
    } catch (error) {
      alert(error.message)
     
    }
       
  }

  const handleCloseModal = () => {
    setShowEndModal(false);
  };

  const handleDateChange = (date) => {
    console.log(date, "checkDate");
    setDateRange((prev) => [
      moment(date[0]).format("YYYY-MM-DD HH:mm"),
      moment(date[1]).format("YYYY-MM-DD HH:mm"),
    ]);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getAllStreamHandler = useCallback(async () => {
    let dateQuery = "",
      statusQuery = "",
      titleQuery = "",
      priceQuery = "";
    if (dateRange) {
      dateQuery = `&startDate=${dateRange[0]}&endDate=${dateRange[1]}`;
    }
    if (streamStatus) {
      statusQuery = `&status=${streamStatus}`;
    }
    if (streamTitle) {
      titleQuery = `&title=${streamTitle}`;
    }
    if (streamMode) {
      priceQuery = `&price=${streamMode}`;
    }
    const res = await axios.get(
      `admin/streams?page=${activePage}${dateQuery}${statusQuery}${titleQuery}${priceQuery}`
    );
    const { data } = res.data;
    console.log(data, "resData");

     setStreams(data.data);
    //setStreams([data.data, ...streams]);
    setCopiedStreams(data.data.reverse());
    setTotalItemsCount(data.totalItems);
  }, [dateRange, streamStatus, streamTitle, streamMode, activePage]);

  useEffect(() => {
    getAllStreamHandler();
  }, [getAllStreamHandler]);

  const handleFilterStream = (e) => {
    const { value } = e.target;

    setStreamStatus(value);
  };
  const handleFilterPayment = (e) => {
    const { value } = e.target;

    setStreamMode(value);
  };

  const endStreamHandler = async (id) => {
    const payload = {
      adminUserId: userData._id,
    };
    const { data } = await axios.post(`api/stream/endStream/${id}`, payload);
    console.log(data);
    getAllStreamHandler();
  };

  const getAllAttendedParticipants = async (id) => {
    handleShow();
    const { data } = await axios.get(
      `admin/profile/transactions?type=STREAM&streamId=${id}`
    );
    console.log(data, "resData");
    for (const innerData of data.data) {
      setAllParticipants(innerData.data);
    }
  };


  const endStream=(id)=>{

  }

  console.log(dateRange, "dateRange");

  console.log("Stream data is", streams)

  return (
    <>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">All Streams</h6>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div id="dataTable_wrapper" className=" dt-bootstrap4">
                <div class="row">
                  <div class="col-sm-12 col-md-4 d-flex align-items-center">
                    <Form.Select
                      className="me-sm-2"
                      id="inlineFormCustomSelect"
                      onChange={(e) => handleFilterStream(e)}
                    >
                      <option value="-1">Select </option>
                      <option value="RUNNING">Live</option>
                      <option value="SCHEDULED">Scheduled</option>
                      <option value="ENDED">Ended</option>
                    </Form.Select>
                    <Form.Select
                      className="me-sm-2"
                      id="inlineFormCustomSelect"
                      onChange={(e) => handleFilterPayment(e)}
                    >
                      <option value="-1">Select </option>
                      <option value="1">Paid</option>
                      <option value="0">Free</option>
                    </Form.Select>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <div className="date_range_box">
                      <DateRangePicker
                        onChange={handleDateChange}
                        value={dateRange}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div id="dataTable_filter" class="dataTables_filter">
                      <InputGroup className="mb-3">
                        <input
                          type="search"
                          class="form-control"
                          placeholder=""
                          aria-controls="dataTable"
                          onBlur={(e) => setStreamTitle(e.target.value)}
                        />
                        <Button variant="outline-secondary" id="button-addon2">
                          Search
                        </Button>
                      </InputGroup>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <table
                      className="table table-bordered "
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                      role="grid"
                      aria-describedby="dataTable_info"
                      style={{ width: 100 + "%" }}
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting sorting_asc"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-sort="ascending"
                            aria-label="Name: activate to sort column descending"
                            style={{ width: 47 + "px" }}
                          >
                            Stream Title <br />
                            Stream ID
                          </th>

                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Office: activate to sort column ascending"
                            style={{ width: 49 + "px" }}
                          >
                            Stream Cost
                            <br />
                            Stream Date and Time
                          </th>

                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Start date: activate to sort column ascending"
                            style={{ width: 31 + "px" }}
                          >
                            Stream Products
                          </th>

                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style={{ width: 67 + "px" }}
                          >
                            Stream Status
                          </th>
                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style={{ width: 67 + "px" }}
                          >
                            Action
                          </th>

                          <th
                            className="sorting"
                            tabindex="0"
                            aria-controls="dataTable"
                            rowspan="1"
                            colspan="1"
                            aria-label="Salary: activate to sort column ascending"
                            style={{ width: 67 + "px" }}
                          >
                            End Stream
                          </th>
                        </tr>
                      </thead>
                      
                      {/* data mapping starts here */}
                      <tbody className="custom-cursor">
                        {streams.map((data) => (
                          <tr className="odd">
                            <td className="sorting_1">
                              {data.title}
                              <br /> {data.id}
                              <p>Total Earning: {data.totalEarning}</p>
                            </td>

                            <td>
                              RVT {data.price == 0 ? "Free" : data.price}
                              <br />
                              <span>
                                {new Date(data.startTime).toLocaleString()}
                              </span>
                            </td>
                            <td>1</td>
                            <td>
                              {data.status}{" "}
                             
                            </td>
                            <td>
                              <button
                                className="btn btn-primary ml-2"
                                onClick={() =>
                                  getAllAttendedParticipants(data.id)
                                }
                              >
                                All Participants
                              </button>
                            </td>



                            <td>
                            {data.status==='RUNNING' && (
                              <button
                                className="btn btn-danger ml-2"
                                onClick={()=>handleEndClick(data._id)}
                              >
                                End Stream
                              </button>
                            )}
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div></div>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    linkClass="page-link"
                    itemClass="paginate_button page-item"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>All Participants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              {allParticipants.length === 0 && (
                <tr>
                  <td colspan="4" className="text-center">
                    No participants
                  </td>
                </tr>
              )}
              {allParticipants.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.viewer.name}</td>
                  <td>{item.viewer.email}</td>
                  <td>{item.viewer.username}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
 

          {/* warning modal to end the stream  */}
      <Modal show={showEndModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to end?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=>handleConfirmEnd(userEndId)} >
            End
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StreamTable;
