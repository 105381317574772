import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";

const User = () => {
  let history = useHistory();
  let { id } = useParams();
  const [tableData, setTableData] = useState([]);

  const getTableById = async () => {
    const res = await axios.get(`admin/get-poker-lobby/${id}`);
    console.log("table data", res.data.data[0]);
    setTableData(res.data.data);
  };

  const handleTransition = () => {
    history.push("/dashboard/lobby");
  };

  useEffect(() => {
    getTableById();
  }, [id]);

  return (
    <>
      <section className="container-fluid">
        {console.log(tableData.room_name, "test name")}
        <div className="row">
          <div className="col-xs-2">
            <div className="w-75 mx-auto shadow p-5">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col-md-11">
                  <h3>View Lobby</h3>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={handleTransition}
                  >
                    BACK
                  </button>
                </div>
              </div>

              <div className="box-body">
                <table className="table table-striped table-bordered">
                  {tableData?.map((game, index) => (
                    <tbody>
                      <tr>
                        <td className="text_view">
                          <b>Game Type</b>
                        </td>
                        <td>:</td>
                        <td>
                          {game.game_type_id === 3
                            ? "Poker"
                            : game.game_type_id}
                        </td>
                        <td className="text_view">
                          <b>Game Category</b>
                        </td>
                        <td>:</td>
                        <td>
                          {game.game_category_id === 2
                            ? "Texas"
                            : game.game_category_id}
                        </td>
                      </tr>
                      <tr>
                        <td className="text_view">
                          <b>Room Name</b>
                        </td>
                        <td>:</td>
                        <td>{game.game_name}</td>
                        <td className="text_view">
                          <b>Multi Run</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.multi_run}</td>
                      </tr>
                      <tr>
                        <td className="text_view">
                          <b>Small Blind</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.small_blind}</td>
                        <td className="text_view">
                          <b>Big Blind</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.big_blind}</td>
                      </tr>
                      <tr>
                        <td className="text_view">
                          <b>Minimum Players</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.minimum_player}</td>
                        <td className="text_view">
                          <b>Maximum Players</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.maximum_player}</td>
                      </tr>
                      <tr>
                        <td className="text_view">
                          <b>Minimum Buyin</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.minimum_buyin}</td>
                        <td className="text_view">
                          <b>Maximum Buyin</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.maximum_buyin}</td>
                      </tr>
                      <tr>
                        <td className="text_view">
                          <b>Commission</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.commission}</td>
                        <td className="text_view">
                          <b>Commission Cap</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.commission_cap}</td>
                      </tr>
                      <tr>
                        <td className="text_view">
                          <b>Turn Timmer</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.turn_timmer}</td>
                        <td className="text_view">
                          <b>Game Timmer</b>
                        </td>
                        <td>:</td>
                        <td>{tableData[0].game_json_data.game_timmer}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default User;
