import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import Card from "./Card";
import EarningOverview from "./EarningOverview";
import RevenueSources from "./RevenueSources";
import ProjectCard from "./ProjectCard";
import Illustration from "./Illustration";
import Approach from "./Approach";
import axios from "axios";
import isEmpty from "is-empty";
const Layout = () => {
  const [dashData, setDashData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get("admin/dashboard");
      console.log(res, "resData");
      const { data } = res.data;
      console.log(data, "dataCheheheh");
      if (!isEmpty(data)) {
        setDashData(data);
      }
    };
    fetchData();
  }, []);
  console.log(dashData, "dashData");
  return (
    <div className="container-fluid">
      <Heading />
      <Card data={dashData} />
      {/* Content Row */}
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="card shadow mb-4">
            {/* Card Header - Dropdown */}
            <EarningOverview />
          </div>
        </div>
        {/* Pie Chart */}
        <div className="col-xl-4 col-lg-5">
          <div className="card shadow mb-4">
            {/* Card Header - Dropdown */}
            <RevenueSources />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
