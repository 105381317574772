import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import isEmpty from "is-empty";
import './ManageFees.css'
const Manage_Fees = () => {
  const [users, setUsers] = useState([]);

  const [userCommission, setUserCommission] = useState('');
  const [comissionData, setComissionData] = useState({
    commission_for_token_to_USD: "",
    commission_for_USD_to_token: "",
    first_time_buyer_fees: "",
  });
  const handleInputComission =(e)=>{
    setComissionData({...comissionData, [e.target.name] : e.target.value});

  }

  let history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState("");
  const [feeDetails, setFeeDetails] = useState({
    currentFees: "",
    streamFees: "",
  });

  const manageFees = async (payLoad, key) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_REVIT_URL}/config/${key}`,
        payLoad
      );

      return res;
    } catch (err) {
      return err;
    }
  };
  
  const handleComission= async (e)=>{
    e.preventDefault();

    const payLoad={
      commission_for_token_to_USD: comissionData.commission_for_token_to_USD,
      commission_for_USD_to_token: comissionData.commission_for_USD_to_token,
      first_time_buyer_fees: comissionData.first_time_buyer_fees,
    }

    // const response = await axios.post('admin/add-commission', comissionData, {
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json',
    //   },
    // })
    // if(response.status===200){
    //   alert(response.data.message)
    // }

    // console.log("comission data response", response);
    console.log("payload is ")
    try {
      console.log("commission data value is", comissionData)
      const updateRes = await axios.put(`admin/update-commission/${userCommission}`, comissionData)
      console.log("update response is", updateRes)
      if(updateRes.status===200){
        alert(updateRes.data.message);
      }
    } catch (error) {
      alert(error.message)
    }
    
  }


  const onSubmit = async (e) => {
    e.preventDefault();
    const { currentFees, streamFees } = feeDetails;
    const _value = parseInt(currentFees) || parseInt(streamFees);
    const payload = {
      value: _value,
    };
    //console.log(payload)
    const res = await manageFees(
      payload,
      currentFees ? "BUY_SALE_ADMIN_SHARE" : "STREAM_AMOUNT"
    );
    console.log(res.response, "res");
    if (res.status === 200) {
      alert("Fee updated successfully!");
    } else {
      alert("Something went wrong!");
    }

    setFeeDetails({
      currentFees: "",
      streamFees: "",
    });
  };

  const getComission= async () => {
    const res= await axios.get('https://api.revitstream.com/v1/admin/get-commission');
    console.log("Comission Data from get api is,", res);
    //console.log("Comission Id is,", res.data.data[0].first_time_buyer_fees);
    
    setComissionData({
      commission_for_token_to_USD: res.data.data[0].commission_for_token_to_USD,
    commission_for_USD_to_token: res.data.data[0].commission_for_USD_to_token,
    first_time_buyer_fees: res.data.data[0].first_time_buyer_fees,
    });
    console.log("user Id is", res.data.data[0]._id);
    setUserCommission(res.data.data[0]._id)
    
  }
  useEffect(() => {
    const fetch_data = async () => {
      const response = await axios.get("admin/config");
      console.log(response.data.data);
      setUsers(response.data.data);
    };
    fetch_data();
    getComission();
  }, []);
  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body ">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          E-commerce Fees
                        </h1>
                      </div>
                      <h5>
                        Current Fees:{isEmpty(users[1]) ? "-" : users[1].value}%
                      </h5>
                      {/*                           
                           <h5>Stream Fees:25%</h5> */}
                      <form className="user" onSubmit={onSubmit}>
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter E-commerce Fees..."
                            required
                            onChange={(e) => {
                              setFeeDetails({
                                ...feeDetails,
                                currentFees: e.target.value,
                              });
                            }}
                            value={feeDetails.currentFees}
                          />
                        </div>

                        <button className="btn btn-primary btn-user btn-block">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Stream Fees</h1>
                      </div>
                      <h5>
                        Stream Fees:{isEmpty(users[0]) ? "-" : users[0].value}%
                      </h5>
                      {/* <h5>Stream Fees:60%</h5> */}
                      <form className="user" onSubmit={onSubmit}>
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Stream Fees..."
                            required
                            onChange={(e) => {
                              setFeeDetails({
                                ...feeDetails,
                                streamFees: e.target.value,
                              });
                            }}
                            value={feeDetails.streamFees}
                          />
                        </div>
                        <button className="btn btn-primary btn-user btn-block">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">
                          Add Comission
                        </h1>
                      </div>
                      <h5>
                        Enter Values to get Comission
                      </h5>
                      <br></br>
                      {/*                           
                           <h5>Stream Fees:25%</h5> */}
                      <form className="user" onSubmit={handleComission}>
                        <div className="form-group">
                        <label className="commission-label">"Enter commission_for_token_to_USD"</label>
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter commission_for_token_to_USD"
                            name="commission_for_token_to_USD"
                            required
                            value={comissionData.commission_for_token_to_USD}
                            onChange={handleInputComission}
                            
                          />
                        
                       <br></br>
                          <label className="commission-label">"Enter commission_for_USD_to_token"</label>
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="commission_for_USD_to_token"
                            name="commission_for_USD_to_token"
                            required
                            value={comissionData.commission_for_USD_to_token}
                            onChange={handleInputComission}
                            
                          /> <br></br>
                          <label className="commission-label">first_time_buyer_fees</label>
                          <input
                            type="number"
                            className="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="first_time_buyer_fees"
                            name="first_time_buyer_fees"
                            required
                            value={comissionData.first_time_buyer_fees}
                            onChange={handleInputComission}
                            
                          />
                        </div>

                        <button className="btn btn-primary btn-user btn-block">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manage_Fees;
